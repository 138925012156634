import React from "react";
import Card from "../components/card";
import Main from "../components/main";
export default function WhatWeDo() {
  let content =
    "At Dakshya A.I, we specialize in Java API development services and the creation of cutting-edge applications tailored for the healthcare and education sectors. Our mission is twofold: to empower individuals pursuing tech careers or startups and to craft products that resonate with millions of users.";
  return (
    <>
      <Main>
        <Card activeContent="What we do" content={content} />
      </Main>
    </>
  );
}
