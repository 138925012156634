import React from "react";
import Card from "../components/card";
import Main from "../components/main";
export default function DataAnalytics() {
  let content =
    "Experience the power of real-time data analytics as your data seamlessly flows through a dynamic pipeline, undergoing diverse transformations to extract meaningful insights. Integrated with advanced AI for predictive analytics, our system goes beyond traditional analytics to provide recommendations and predictions, empowering you with actionable insights for informed decision-making and robust business growth";
  return (
    <>
      <Main>
        <Card activeContent="Data Analytics" content={content} />
      </Main>
    </>
  );
}
