
import React, { useState } from "react";
import fb from "../images/f4.jpeg";
import yo from "../images/y3.jpeg";
import Blogs from "./blogs";
import Main from "../components/main";
import Benefit_card from "../components/benefit_card";

export default function Benefit({ children }) {
  let content = "Everything you need to know is how to get started.";
  const [activeContent, setActiveContent] = useState("default");

  function handleProductClick(content) {
    setActiveContent(content);
  }

  return (
    <Main>
      <Blogs onClick={handleProductClick} />
      <div>{children}</div>

      <div className="">
        <Benefit_card activeContent="HOW IT WORKS?" content={content} />
      </div>

      <div className="row mb-4">
        <div className="col-sm-3 mb-3 mb-sm-2">
          <div className="card" style={{ width: "16rem", height: "100%" }}>
            <img
              src={fb}
              className="card-img-top"
              alt="..."
              style={{ height: "200px", objectFit: "cover", backgroundColor: "#f8f9fa" }}
            />

            <div className="card-body">
              <p className="card-text">
                Browse through this Facebook page to get more information
              </p>
              <a
                href="https://www.facebook.com/profile.php?id=61558219126778"
                className="btn btn-primary"
              >
                Follow us
              </a>
            </div>
          </div>
        </div>

        <div className="col-sm-3 mb-3 mb-sm-2">
          <div className="card" style={{ width: "16rem", height: "100%" }}>
            <img
              src={yo}
              className="card-img-top"
              alt="..."
              style={{ height: "200px", objectFit: "cover", backgroundColor: "#f8f9fa" }}
            />

            <div className="card-body">
              <p className="card-text">
                Follow this video step by step to get started.
              </p>
              <a
                href="https://www.youtube.com/@DiabetesSugarTracker/videos"
                className="btn btn-primary"
              >
                Watch Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
}
