import React from "react";
import { Link } from "react-router-dom";
export default function Card({ activeContent,content }) {
  return (
    <div class="card bg-primary mt-5 text-center rounded-0">
    <div class="card-body p-4">
  

      <h1 class="card-title display-4 fw-normal text-lg text-white mt-4 mb-3">
        {activeContent}
      </h1>

     {/* <p class='card-title display-4 fw-normal mt-4 mb-3'>{activeContent}</p> */}
  

      <p class="card-text text-white mt-3 mb-3">
        {content}
      </p>
  

      <Link to="/contact-us" class="btn btn-primary btn-lg rounded-1 bg-white text-primary mt-4 mb-3">
        Contact Us
      </Link>
  
    </div>
  </div>
  
  );
}
