
import React, { useState } from "react";
import Blogs from "./blogs";
import Main from "../components/main";

import f from "../images/fb.png";
import y from "../images/you.png";
import l from "../images/link.png";

export default function Follow_us({ children }) {
  let content = "Everything you need to know is how to get started.";
  const [activeContent, setActiveContent] = useState("default");

  function handleProductClick(content) {
    setActiveContent(content);
  }

  return (
    <Main>
      <Blogs onClick={handleProductClick} />
      <div>{children}</div>
      <div className="card mt-5 bg-light">
        <div className="card-body">
        <a href="https://www.facebook.com/groups/1485619165721771">
          <h5 className="card-title no-underline text-center">Join our support community</h5>
          </a>
         

          <h3 className="text-center text-primary mt-3">Follow us on our Social Network</h3>

          <div className="d-flex justify-content-center m-auto mt-4">
          
        <a href="https://www.facebook.com/profile.php?id=61558219126778">
            <img src={f} className="img-fixed-size p-1" alt="Facebook" /></a>
            <a href='https://www.youtube.com/@DiabetesSugarTracker'>
            <img src={y} className="img-fixed-size p-1" alt="YouTube" /></a>
            <a href="https://www.linkedin.com/company/diabetessugartracker/posts/?feedView=all">
            <img src={l} className="img-fixed-size p-1" alt="LinkedIn" /></a>
          </div>
        </div>
      </div>
    </Main>

   
  );
}
