import React from 'react';


function Support() {
    return (
        <div className="container mt-5">
    
          {/* Blood Sugar Tracker Description */}
          <section className="mt-4">
            <h2>Diabetes Sugar Tracker</h2>
            <p>
              Our Diabetes Sugar Tracker App is designed to help you manage your diabetes effectively. Keep a close eye on your blood sugar levels, record essential details, and take control of your health.
            </p>
          </section>
    
          {/* Feature of Diabetes Sugar Tracker */}
          <section className="mt-4">
            <h1>Key Features</h1>
            <h3>Blood Sugar Tracking</h3>
            <ul>
              <li><strong>Record sugar readings:</strong> Easily add, update, or delete blood sugar readings.</li>
              <li><strong>Record Details:</strong> Record essential details such as Date, Time, and Blood Glucose level.</li>
              <li><strong>Unit Specification:</strong> Specify the unit of measurement in mg/dl or mmol/L.</li>
              <li><strong>Meal Categorization:</strong> Categorize measurements based on meal times, including After Lunch, Before Lunch, After Dinner, Before Dinner, Breakfast, After or Before Sleep, Fasting, and other custom options.</li>
            </ul>
          </section>
    
          {/* Notes Section */}
          <section className="mt-4">
            <h3>Notes</h3>
            <ul>
              <li><strong>Capture Important Information:</strong> Add notes to capture important information.</li>
              <li><strong>Date Inclusion:</strong> Include the date along with your notes for accurate record-keeping.</li>
            </ul>
          </section>
    
          {/* Weight Section */}
          <section className="mt-4">
            <h3>Weight</h3>
            <ul>
              <li><strong>Monitor Weight Accurately:</strong> Keep track of your weight with precision.</li>
              <li><strong>Unit Flexibility:</strong> Input details like Date, Weight, and choose the unit of measurement - Kilogram or Pound.</li>
              <li><strong>Additional Notes:</strong> Add extra notes to provide context or relevant details.</li>
            </ul>
          </section>
    
          {/* Medication Section */}
          <section className="mt-4">
            <h3>Medication</h3>
            <ul>
              <li><strong>Effective Medication Management:</strong> Manage your medication information effectively.</li>
              <li><strong>New Medications:</strong> Add new medications, specifying the unit of measure - units, mg, mL, or tablets.</li>
              <li><strong>Dosage Recording:</strong> Record dosage details in alignment with the selected unit of measure. Include the date and time of medication intake.</li>
            </ul>
          </section>
    
          {/* Why Choose Our Diabetes Sugar Tracker */}
          <section className="mt-4">
            <h1>Why Choose Our Diabetes Sugar Tracker?</h1>
            <ol>
              <li><strong>Comprehensive Monitoring:</strong> Seamlessly track blood sugar levels, weight, notes, and medications in one centralized platform.</li>
              <li><strong>User-Friendly Interface:</strong> Intuitive design for easy navigation, making health management accessible to all users.</li>
              <li><strong>Personalized Insights:</strong> Gain valuable insights into your health trends through visualizations.</li>
              <li><strong>Holistic Approach:</strong> Address every aspect of diabetes management, from glucose levels to lifestyle.</li>
            </ol>
          </section>
    
          {/* Steps to Use Section */}
          <section className="mt-4">
            <h1>Steps to Use</h1>
            <ol>
              <li>Open the app and tap "Blood Sugar" to log blood sugar levels.</li>
              <li>Use "Notes" for daily details like meals or exercise, symptoms.</li>
              <li>Track weight changes in the "Weight" section.</li>
              <li>Ensure timely medication intake using “Medication”.</li>
            </ol>
          </section>
    
          {/* Contact Us Section */}
          <section className="mt-4">
            <h2>Contact Us</h2>
            <p>If you have any questions or queries, feel free to contact us
            at info@dakshyaai.com
            </p>
          </section>
    
        </div>
      );
    }

export default Support;
