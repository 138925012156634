import React from "react";
import Card from "../components/card";
import Main from "../components/main";
export default function Aiml() {
  let content =
    "Transform your ideas into market-ready MVPs swiftly with our expert team specializing in the latest AI implementations. From concept to production, we bring efficiency and precision to AI-based product development, ensuring your vision becomes a reality in record time";
  return (
    <>
      <Main>
        <Card activeContent="AI & Machine Learning" content={content} />
      </Main>
    </>
  );
}
