import React from "react";

export default function Benefit_card({ activeContent,content }) {
  return (
    <div class="mt-auto text-center rounded-0">
    <div class="card-body p-4">
  

      <h3 class="card-title display-4 fw-normal text-lg mt-4 mb-3 text-primary fs-2">
        {activeContent}
      </h3>

     {/* <p class='card-title display-4 fw-normal mt-4 mb-3'>{activeContent}</p> */}
  

      <h5 class="card-text mt-3 mb-3 text-primary">
        {content}
      </h5>
      
    </div>
  </div>
  
  );
}
