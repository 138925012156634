import React, { useState } from "react";
import Blogs from "./blogs";
import Main from "../components/main";
import Benefit_card from "../components/benefit_card";
import "../index.css";
export default function Benefit({ children }) {
  const [activeContent, setActiveContent] = useState("default");

  function handleProductClick(content) {
    setActiveContent(content);
  }
  return (
    <Main>
      <Blogs onClick={handleProductClick} />
      <div>{children}</div>
      {/* <Blogs /> */}

      <div class="">
        <Benefit_card
          activeContent="Benefits of diabetes sugar tracker app"
          content=""
        />
      </div>

      <div class="container my-4">
        <div class="row mb-3">
          <div class="col-sm-6 mb-4">
            <div class="card hover-effect h-100">
              <div class="card-body">
                <h5 class="card-title">All-in-One Management</h5>
                <p class="card-text">
                  Keep all your diabetes information in one place.
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-6 mb-4">
            <div class="card hover-effect h-100">
              <div class="card-body">
                <h5 class="card-title">Personal Insights</h5>
                <p class="card-text">
                  See how your diet and symptoms affect your blood sugar.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-sm-6 mb-4">
            <div class="card hover-effect h-100">
              <div class="card-body">
                <h5 class="card-title">Weight Tracking</h5>
                <p class="card-text">
                  <ul>
                    <li>Record and monitor your weight changes over time.</li>
                    <li>
                      Understand how weight fluctuations impact your blood sugar
                      levels.
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-6 mb-4">
            <div class="card hover-effect h-100">
              <div class="card-body">
                <h5 class="card-title">Medication Tracking</h5>
                <p class="card-text">
                  Manage your medication schedule and dosages effectively.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-sm-6 mb-4">
            <div class="card hover-effect h-100">
              <div class="card-body">
                <h5 class="card-title">Exercise and Food Impact</h5>
                <p class="card-text">
                  <ul>
                    <li>
                      Visualize how exercise and different foods influence your
                      blood sugar levels.
                    </li>
                    <li>
                      Make informed decisions about your lifestyle and diet to
                      manage diabetes better.
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-6 mb-4">
            <div class="card hover-effect h-100">
              <div class="card-body">
                <h5 class="card-title">All-in-One Management</h5>
                <p class="card-text">
                  Keep all your diabetes information in one place.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-sm-6 mb-4">
            <div class="card hover-effect h-100">
              <div class="card-body">
                <h5 class="card-title">Easy to Use</h5>
                <p class="card-text">
                  The app is simple and intuitive, making it easy to stay
                  organized and informed.
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-6 mb-4">
            <div class="card hover-effect h-100">
              <div class="card-body">
                <h5 class="card-title">All-in-One Management</h5>
                <p class="card-text">
                  Keep all your diabetes information in one place.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
}
