import React from "react";
import Form from "../components/form";
import Main from "../components/main";
export default function Contact() {
  let content ="You can mail us at info@dakshyaai.com"
   return (
    <>
      <Main>
      <div class="card bg-primary pt-5 pe-5 ps-5 mt-5 text-center rounded-0">
      <div class="card-body p-5">
        <h1 class="card-title display-1 text-lg fw-normal text-white mt-4 mb-2 ">
        Contact us
        </h1>
        <p class="card-text text-white ps-4 pe-4 ms-5 me-5 mt-4 mb-5">
          {content}
        </p>
           </div>
        </div>
       <Form />
      </Main>
    </>
  );
}
