import React, { useState } from "react";
import Navbar from "./navbar";
import Footer from "./footer";
import GetStarted from "./getstarted";

export default function Main({children}) {
  const [activeContent, setActiveContent] = useState("default");

  function handleNavbarClick(content) {
    setActiveContent(content);
  }
  return (
    <>
    <Navbar onClick={handleNavbarClick} />
    <div>{children}</div>
    <GetStarted />
    <Footer onClick={handleNavbarClick} />
  </>
  );
}
