import React from "react";
import Card from "../components/card";
import Main from "../components/main";
export default function Startup() {
  let content =
    "Our mission is to propel early-stage tech startups to success by developing cutting-edge technology, be it micro SaaS or SaaS products. At each step, we provide expert guidance to ensure the creation of top-quality products poised to be embraced by millions of users, whether on the web, mobile, or in the realm of high-tech, leveraging the transformative potential of AI. Your vision, our expertise, driving innovation for widespread impact.";
  return (
    <>
      <Main>
        <Card activeContent="Early Stage Startup" content={content} />
      </Main>
    </>
  );
}
