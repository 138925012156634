import React,{useState} from "react";
import axios from "axios";
export default function Form(){
   const initialFormData={
    fullName: '',
    companyName:'',
    companyEmail: '',
    talkAbout:'',
    comment: ''
   }
    const [formData, setFormData] = useState(initialFormData);
    
      const handleChange = (e) => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value
        });
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        axios.post('https://api.dakshyaai.com/api/web/contact-us',formData).then((respones)=>{
            alert('Thank You For Inquiry,We will  get back to you soon');
        })
        .catch((error)=>{
            alert(error);
        })
        setFormData(initialFormData);
        
      };

    return(<div class="container w-50 mx-auto m-5 p-3 ">
        <h3 class="pb-4">Tell us about <span class="text-primary">your idea</span></h3>
        <form onSubmit={handleSubmit} class="row g-3">
            <div class="col-md-12">
              <input type="text" class="form-control"  name="fullName" value={formData.fullName} onChange={handleChange} placeholder="Full Name" required />
             </div>
             <div class="col-md-12">

              <input type="text" class="form-control" name="companyName" value={formData.companyName} onChange={handleChange} placeholder="Company Name" required />
             </div>
           <div class="col-md-12">
            <input type="email" class="form-control" name="companyEmail" value={formData.companyEmail} onChange={handleChange} placeholder="Company Email" required />
           </div>
           <div class="col-md-12">

             <input type="text" class="form-control" name="talkAbout" value={formData.talkAbout} onChange={handleChange} placeholder="Subject" required />
          </div>
        <div class="col-md-12">
            <textarea class="form-control" name="comment" value={formData.comment} onChange={handleChange} rows="4" placeholder="tell us about your project" required></textarea>
         </div>
  <div class="col-md-12">
    <button type="submit" class="btn btn-secondary">Submit</button>
  </div>
     </form>
</div>
    );
}
