import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faLinkedin,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";
import Option from "./option";
import { Link } from "react-router-dom";
let dates = new Date().getFullYear();
export default function Footer({ onClick }) {
  const linkStyle = {
    textDecoration: 'none', // Remove underlines from the link
    color: 'white', // Change link color to red (or any other color you prefer)
  };


  function handleButtonClick(content) {
    onClick(content);
  }
  return (
    <div>
      <footer class="bg-dark text-white container-fluid  pt-2 pb-2">
        <div class="container-fluid  text-md-left">
          <div class="row text-center text-md-left">
            <div class="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
              <h5 class="font-weight-bold mb-3 text-warning">Company</h5>
              <Option
                href="#"
                to="/about-us"
                onClick={() => handleButtonClick("About us")}
                btncontent="About us"
              />
              <Option
                to="/what-we-do"
                href="#"
                onClick={() => handleButtonClick("What we do")}
                btncontent="What we do"
              />
            </div>
            <div class="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3 ">
              <h5 class="font-weight-bold mb-3 text-warning">Services</h5>
              <Option
                to="/services/AI/ML"
                onClick={() => handleButtonClick("AI/ML")}
                href="#"
                btncontent="AI Based Product Development"
              />
              <Option
                to="/services/data-analytics"
                onClick={() => handleButtonClick("Data Analytics")}
                href="#"
                btncontent="Data Analytics"
              />
              <Option
                to="/services/startup"
                onClick={() => handleButtonClick("Startup")}
                href="#"
                btncontent="Early Stage Startup"
              />
            </div>
            <div class="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
              <h5 class="font-weight-bold mb-3 text-warning">Insights</h5>
              <Option
                href="#"
                to="/#"
                onClick={() => handleButtonClick("blogs")}
                btncontent="Blogs"
              />
                          <a href="https://www.facebook.com/profile.php?id=100088380852723">
              <FontAwesomeIcon icon={faFacebook} className="text-white m-2" />
            </a>
            <a href="https://www.linkedin.com/company/dakshyaai/">
              <FontAwesomeIcon icon={faLinkedin} className="text-white m-2" />
            </a>
            </div>
            <div class="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
              <h5 class="font-weight-bold mb-3 text-warning">Join us</h5>
              <Option
                to="/internship"
                onClick={() => handleButtonClick("internship")}
                href="#"
                btncontent="Internship"
              />
              <Option
                to="/careers"
                onClick={() => handleButtonClick("Careers")}
                href="#"
                btncontent="Jobs"
              />
            </div>
          </div>
        </div>
        <div class="row container-fluid text-center mt-4 text-md-left">
          <div class="col-md-4 col-lg-4 col-xl-4 me-5 pe-5  pt-2 ">
          </div>
          <div class=" col-md-3 col-lg-3 col-xl-3 ps-5 me-5 mx-auto pt-2 ">
            <a href="https://www.facebook.com/profile.php?id=100088380852723">
              <FontAwesomeIcon icon={faFacebook} className="text-white m-2" />
            </a>
            {/* <a href="https://twitter.com/">
              <FontAwesomeIcon icon={faTwitter} className="text-white m-2" />
            </a> */}
            <a href="https://www.linkedin.com/company/dakshyaai/">
              <FontAwesomeIcon icon={faLinkedin} className="text-white m-2" />
            </a>
            {/* <a href="https://github.com/login">
              <FontAwesomeIcon icon={faGithub} className="text-white m-2" />
            </a> */}
          </div>
        </div>
      </footer>
      <div class=" bg-secondary fixed-bottom container-fluid text-white text-md-left">
        <div class="row align-items-center">
          <div class="col-md-11 col-lg-11 mx-auto">
          <p class="text-left pe-5  py-auto pt-2 ">
              Copyright @ {dates} Dakshya A.I  Pvt. Ltd. 
              {' '}

         <Link to="/privacy-policy" style={linkStyle}>
         Privacy Policy
       </Link>


            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
