import React from "react";
import { Link } from "react-router-dom";
export default function GetStarted(){
    return(
        <div class="container-fluid bg-warning text-white">
             <div class="row px-5">
              <div class="col-md-7  text-left col-lg-7 col-xl-7 my-4  ps-5 py-3 pe-2">
                <h5>We specialize in AI-based product development, focusing on Health Tech and EdTech solutions.</h5>
              </div>
              <div class="col-md-5  col-lg-5 col-xl-5 text-end py-3 pe-5 ps-5 my-4">
            {/* <Link to="/contact-us">  <button class="btn btn-light text-primary btn-md  px-4 py-2 rounded-1">Get started</button></Link> */}
             </div>
              </div>
    </div>)
}