import React from "react";
import {Link} from "react-router-dom";
export default function Option(props){

    return (
    <li class="nav-item  list-unstyled">
        <Link to={props.to} onClick={props.onClick} class="dropdown-item nav-link" href={props.href} >
            <h5>{props.btncontent}</h5>
        </Link>
    </li>)
}