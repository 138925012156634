import React from 'react';


const PrivacyPolicy = () => {
  const sectionStyle = {
    marginLeft: '30px', // Left spacing added
    marginBottom: '20px' // Bottom margin added for spacing between sections
  };

  return (
    <div>
     

      <div style={sectionStyle}> {/* Apply left spacing to each section */}
      <h2>Privacy Policy for Diabetes Sugar Tracker</h2>
      <p>Last Updated: 2024-02-20</p>
        <h3>Introduction</h3>
        <p>Welcome to Diabetes Sugar Tracker, a mobile application designed to help you manage your blood sugar levels effectively. This privacy policy outlines how we handle the information you provide while using our app.</p>
      </div>

      <div style={sectionStyle}>
        <h3>Information We Collect</h3>
        <ul>
          <li><strong>Personal Data:</strong> We do not collect any personal information such as your name, age, date of birth, or sex.</li>
          <li><strong>Health-Related Data:</strong> The app collects data that you voluntarily provide, including:
            <ul>
              <li>Weight</li>
              <li>Blood sugar readings</li>
              <li>Medication details</li>
              <li>Notes related to your health</li>
            </ul>
          </li>
        </ul>
      </div>

      <div style={sectionStyle}>
        <h3>How We Use Your Data</h3>
        <p>Your data is used solely for the purpose of providing you with the services of our app. This includes:
          <ul>
            <li>Tracking and managing your blood sugar levels.</li>
            <li>Helping you monitor the effectiveness of your medication.</li>
            <li>Assisting you in understanding how various factors affect your blood sugar.</li>
          </ul>
        </p>
      </div>

      <div style={sectionStyle}>
        <h3>Data Storage and Security</h3>
        <p>
          <strong>Local Storage:</strong> All data you enter into the app is stored locally on your iPhone. We do not transmit or store your data on any servers.<br />
          <strong>Data Deletion:</strong> If you delete the app from your iPhone, all data stored within the app will be permanently deleted and cannot be recovered.
        </p>
      </div>

      <div style={sectionStyle}>
        <h3>User Consent</h3>
        <p>By using Diabetes Sugar Tracker, you consent to the data practices described in this privacy policy.</p>
      </div>

      <div style={sectionStyle}>
        <h3>Changes to This Privacy Policy</h3>
        <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
      </div>

      <div style={sectionStyle}>
        <h3>Contact Us</h3>
        <p>If you have any questions about this Privacy Policy, please contact us at:</p>
        <p>Email: info@dakshyaai.com</p>
        <p>Address: Pokhara, Nepal</p>
        <p>Visit our website for more information.</p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
