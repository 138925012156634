import React from "react";
import Card from "../components/card";
import Main from "../components/main";
export default function InnovationLab() {
  let content =
    "Our inhouse R&D labs are continuously looking for new methods and approaches to improve services and products. We are actively seeking ways to continuously improve expertise and adopt state of the art technologies and innovate new products.";
  return (
    <Main>
      <Card activeContent="Innovation Lab" />
    </Main>
  );
}
