import React from "react";
import Card from "../components/card";
import Main from "../components/main";
export default function FellowShip() {
  let content = "Are you energetic, young, and bursting with creativity? We're on the lookout for individuals like you to drive the development of cutting-edge products. If you're ready to be part of an innovative team and gain hands-on experience, apply for our internship openings by sending your resume to info@dakshyaai.com. Unleash your potential and be a catalyst for groundbreaking solutions!"
  return (
    <Main>
      <Card activeContent="Internship Program" content={content} />
    </Main>
  );
}
