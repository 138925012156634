import React from "react";
import Card from "../components/card";
import Main from "../components/main";
export default function Careers() {
  let content =
    "We are  looking for associate CTO ,senior Generative AI engineer ,IOT researcher ,Senior React Engineers.Please send us your resume at info@dakshyaai.com ";
  return (
    <>
      <Main>
        <Card activeContent="Jobs" content={content} />
      </Main>
    </>
  );
}
