import React from "react";
import Card from "../components/card";
import Main from "../components/main";
export default function Aboutus() {
  let content =
    "Welcome to Dakshya A.I where technology meets healthcare and education innovation. We are On a mission to revolutionize health care and EdTech industry with power of AI. With a combined experience of 24 years, our co-founders bring a unique blend of expertise—one with 14 years in technology and the other with a decade in the health industry.We are located in Pokhara ,Nepal which is heaven on earth.";
  return (
    <Main>
      <Card activeContent="About us" content={content} />
    </Main>
  );
}
